import React, { lazy } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import NormalLayout from "./layouts/NormalLayout";
import UnprotectedRoute from "./containers/UnprotectedRoute";
// import ProtectedRoutes from "./containers/ProtectedRoutes";

const Home = lazy(() => import("./pages/Home"));
const Share = lazy(() => import("./pages/Share"));
const Page404 = lazy(() => import("./pages/Page404"));
const TermsOfUse = lazy(() => import("./components/Termsofuse/TermsOfUse"));

// const Login = lazy(() => import("./pages/Login"));

const HOME_ROUTE = "/";
const SHARE_ROUTE = "/share/:id";
const LOGIN_ROUTE = "/login";
const TERMS_OF_USE_ROUTE = "/terms-of-use";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={HOME_ROUTE}
          element={
            <UnprotectedRoute layout={NormalLayout} redirectTo={LOGIN_ROUTE}>
              <Home />
            </UnprotectedRoute>
          }
        />
        <Route
          exact
          path={SHARE_ROUTE}
          element={
            <UnprotectedRoute redirectTo={LOGIN_ROUTE}>
              <Share />
            </UnprotectedRoute>
          }
        />
        {/* <Route
          path={LOGIN_ROUTE}
          element={
            <UnprotectedRoute redirectTo={HOME_ROUTE}>
              <Login />
            </UnprotectedRoute>
          }
        /> */}
        {/* <Routes> */}
        <Route
          exact
          path={TERMS_OF_USE_ROUTE}
          element={
            // <UnprotectedRoute layout={NormalLayout}>
            <TermsOfUse />
            // </UnprotectedRoute>
          }
        />

        <Route path={"*"} element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
