import { useSelector } from "react-redux";
import { ScreenType, useHomeContext } from "../../contexts/HomeContextProvider";
import styles from "./index.module.scss";
import { useEffect } from "react";
import { updateUserData } from "../../firebase/auth";

const StartScreen = () => {
  const { updateScreen } = useHomeContext();
  const user = useSelector((state) => state.user);



 

  const handleCTA = () => {
    updateScreen(user ? ScreenType.TEMPLATE_SCREEN : ScreenType.FORM_SCREEN);
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img
          className={styles.logo}
          src={require("../../assets/images/brandLogoDesktop.png")}
          alt="Docflix"
        />
        <h2 className={styles.title}>
          Care For The <span style={{ color: "var(--primaryColor)" }}>Eyes</span> 
        </h2>
        <img
          className={styles.heart}
          src={require("../../assets/images/heartdayLogo.png")}
        />
      </div>
      <div className={styles.right}>
        <h1>
          Care For The <span style={{ color: "var(--primaryColor)" }}>Eyes</span> 
        </h1>
        <h2>
        Generate personalised video messages for patient awareness.
        </h2>
        <button className={`btn ${styles.myBtn}`} onClick={handleCTA}>
          {user ? "DASHBOARD" : "TRY NOW"}
        </button>
      </div>
    </div>
  );
};

export default StartScreen;
